import Cookies from "js-cookie";
import moment from "moment-timezone";
import { IBounds, IDecodedToken } from "types/global";
import { COOKIES_KEYS } from "./cookies";
import jwt_decode from "jwt-decode";
import { handleError } from "services";
import { AllRoutes } from "routes/AllRoutes";
import {
  ProviderSidebarRoutes,
  clientOfflineSidebarRoutes,
  clientSidebarRoutes,
} from "components/global/Layouts/DashboardLayout/SideBarRoutes";
import { clientRoutes, providerRoutes } from "routes";

export function attachSubdomainPath(portal: string, route: string): string {
  const protocol = window.location.protocol + "//";
  const host = window.location.host
    .replace("www.", "")
    .replace(`${getValidSubdomain()}.`, "")
    .replace(`staging.`, "");
  const isLive = process.env.REACT_APP_ENV === "production";

  return isLive
    ? protocol + portal + "." + host + route
    : protocol + portal + ".staging." + host + route;
}

export function isLocalhost(): boolean {
  return typeof window !== "undefined" && window.location.hostname.includes("localhost");
}

export function isStagingDomain(): boolean {
  return typeof window !== "undefined" && window.location.hostname.includes(".staging.");
}

export function isVercelDomain(): boolean {
  return typeof window !== "undefined" && window.location.hostname.includes(".vercel.app");
}

export function isDashboardDomain(): boolean {
  return getValidSubdomain() === "dashboard";
}

export function getValidSubdomain() {
  let subdomain: string = "register";
  let host: string | null = null;

  if (typeof window !== "undefined") {
    host = window.location.host;
  }

  if (host && host.includes(".")) {
    const hostParts = host.split(".");

    if (
      // Localhost subdomain
      (isLocalhost() && hostParts.length > 1) ||
      // Vercel subdomain
      (isVercelDomain() && hostParts.length > 3) ||
      // Sendrail subdomain
      (!isLocalhost() && !isVercelDomain() && hostParts.length > 2)
    ) {
      const candidate = hostParts[0];

      if (candidate && candidate !== "www") {
        // Valid candidate
        subdomain = candidate;
      }
    }
  }

  return subdomain.toLowerCase();
}

export function isClientLoggedIn(): boolean {
  return (
    !!Cookies.get(COOKIES_KEYS.TOKEN) && Cookies.get(COOKIES_KEYS.USER_TYPE) === "customerOneTime"
  );
}

export function isAuthExcludedPage(): boolean {
  const excludedRoutes = [
    AllRoutes.clientEntryPoint,
    AllRoutes.clientDashboard,
    AllRoutes.clientCreateSingleOrder(""),
  ];

  return typeof window !== "undefined" && excludedRoutes.includes(window.location.pathname);
}

export function isRideHailingTenant(): boolean {
  const rideHailingTenants = isStagingDomain() ? ["ride"] : ["awesome"];

  return rideHailingTenants.includes(getValidSubdomain());
}

export function isTenantRequiredPages(): boolean {
  const pages = [
    AllRoutes.clientLogin,
    ...providerRoutes.map(i => i?.path),
    ...clientRoutes.map(i => i?.path),
  ];

  return typeof window !== "undefined" && pages.includes(window.location.pathname);
}

export function isComingSoonPage(): boolean {
  const routes = [...ProviderSidebarRoutes, ...clientSidebarRoutes, ...clientOfflineSidebarRoutes];
  const comingSoonRoutes = routes.filter(i => i?.isComingSoon);
  const currentPathname = window.location.pathname;

  const matchedRoutes = comingSoonRoutes.filter(i => currentPathname.startsWith(i.href));

  return matchedRoutes.length > 0;
}

export function generateRandomNumbers(length: number): string {
  const digits = "0123456789";

  let code = "";

  for (let i = 0; i < length; i++) {
    code += digits[Math.floor(Math.random() * 10)];
  }

  return code;
}

export const formatAmount = (amount: string | number = "") => {
  return Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(Number(amount));
};

export function formatDate(date: string | Date) {
  return moment.tz(date, moment.tz.guess()).format("llll");
}

export function formatDateToFormField(date: string | number) {
  return moment.tz(date, moment.tz.guess()).format("Y-MM-DD");
}

export function formatDateWithSlash(date: string | number) {
  return moment.tz(date, moment.tz.guess()).format("DD/MM/Y");
}

export const isEmptyObject = (obj: Record<string, any>) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const removeNullKey = <T,>(obj: T) => {
  const json = { ...obj };
  for (let i in json) {
    if (Object.prototype.hasOwnProperty.call(json, i)) {
      const element = json[i];
      if (!element) {
        delete json[i];
      }
    }
  }
  return json;
};

export const setUpQuery = (json: Record<string, any>) => {
  let query = "?";
  if (isEmptyObject(json)) {
    return "";
  }
  for (const i in removeNullKey(json)) {
    query += encodeURIComponent(i) + "=" + encodeURIComponent(json[i]) + "&";
  }
  return query.replace(/&$/g, "");
};

export const calculateZoom = (bounds: IBounds) => {
  const WORLD_SIZE = 512; // Mapbox tile size
  const ZOOM_MAX = 20; // Maximum zoom level

  const latFraction = (bounds.maxLat - bounds.minLat) / 180;
  const lonFraction = (bounds.maxLon - bounds.minLon) / 360;

  const latZoom = Math.floor(Math.log(WORLD_SIZE / 256 / latFraction) / Math.LN2);
  const lonZoom = Math.min(
    Math.floor(Math.log(WORLD_SIZE / 256 / lonFraction) / Math.LN2),
    ZOOM_MAX
  );

  return Math.min(latZoom, lonZoom, ZOOM_MAX);
};

export function convertMetersToKilometers(meters: number) {
  return capAtTwoDecimalPlaces(meters / 1000); // There are 1000 meters in a kilometer
}

export function convertSecondsToNearestTime(seconds: string | number) {
  seconds = Number(seconds);

  if (seconds < 3600) {
    return Math.round(seconds / 60) + " mins";
  } else {
    return capAtTwoDecimalPlaces(seconds / 3600) + " hrs";
  }
}

export function capAtTwoDecimalPlaces(number: number) {
  return parseFloat(number.toFixed(2));
}

export function conertKoboToNaira(amount: number | string): number {
  return Number(amount) / 100;
}

export function openInNewTab(url: string) {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function scrollToSection(sectionID: string) {
  const el = document.querySelector(`#${sectionID}`);
  el?.scrollIntoView({ block: "start" });
}

export function getDecodedToken(token: string): IDecodedToken | null {
  try {
    if (!token) {
      // eslint-disable-next-line no-throw-literal
      throw { message: "Token Not Found", status: 401 };
    }

    return jwt_decode(token);
  } catch (error) {
    handleError(error);
  }
}
