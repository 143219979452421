import { MainLoader } from "components/global";
import NotFoundPage from "pages/NotFoundPage";
import React, { useEffect, useState } from "react";
import { selectTenantProfile } from "redux/slices/authSlice";
import { useAppSelector } from "redux/store";
import RenderRoutes from "routes";
import { fetchTenantProfile } from "services/auth";
import { isDashboardDomain, isTenantRequiredPages } from "utils/utilFunctions";

function App() {
  const tenantProfile = useAppSelector(selectTenantProfile);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      if (!isDashboardDomain()) await fetchTenantProfile();

      setIsLoading(false);
    }

    fetchData();
  }, []);

  if (isDashboardDomain() && isTenantRequiredPages()) return <NotFoundPage />;

  if (!isDashboardDomain()) {
    if (isLoading) return <MainLoader />;

    if (!tenantProfile) return <NotFoundPage />;
  }

  return <RenderRoutes />;
}

export default App;
