import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "pages/HomePage";
import ComponentsPage from "pages/ComponentsPage";
import ColorsPage from "pages/ColorsPage";

import {
  ProviderLoginPage,
  SignUpPage,
  ProviderSignUpPage,
  ClientSignUpPage,
  CourierSignUpPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  VerificationPage,
  ClientLoginPage,
} from "pages/auth";

import {
  DashboardPage,
  AccountSetupPage,
  ShipmentPage,
  CreateBulkOrderPage,
  CreateSingleOrderPage,
  CustomerPage,
  AddCustomerPage,
  CourierPage,
  AddCourierPage,
  ViewCourierPage,
  ShipmentRoutePage,
  PayrollPage,
  CreatePayrollPage,
  EditPayrollPage,
  TransactionsPage,
  SettingsPage,
} from "pages/admin";

import {
  ClientDashboardPage,
  ClientEstimatePage,
  ClientProfilePage,
  ClientOrderHistoryPage,
  ClientCreateSingleOrderPage,
  ClientRecentActivitiesPage,
  ClientBookARidePage,
} from "pages/client";

import { AllRoutes } from "./AllRoutes";
import { ProtectAuth } from "components/global";
import { IRoute } from "types/global";
import NotFoundPage from "pages/NotFoundPage";
import HandoverPage from "pages/HandoverPage";
import { isRideHailingTenant } from "utils/utilFunctions";

// Routes without Protection
export const routes: IRoute[] = [
  { path: "/", component: <HomePage /> },

  { path: AllRoutes.components, component: <ComponentsPage /> },
  { path: AllRoutes.colors, component: <ColorsPage /> },

  { path: AllRoutes.providerLogin, component: <ProviderLoginPage /> },
  { path: AllRoutes.providerLoginHandover, component: <HandoverPage /> },
  { path: AllRoutes.clientLogin, component: <ClientLoginPage /> },

  { path: AllRoutes.signUp, component: <SignUpPage /> },
  { path: AllRoutes.providerSignUp, component: <ProviderSignUpPage /> },
  { path: AllRoutes.clientSignUp, component: <ClientSignUpPage /> },
  { path: AllRoutes.courierSignUp, component: <CourierSignUpPage /> },

  { path: AllRoutes.forgotPassword, component: <ForgotPasswordPage /> },
  { path: AllRoutes.resetPassword(""), component: <ResetPasswordPage /> },

  { path: AllRoutes.verifyEmail(""), component: <VerificationPage /> },

  { path: "*", component: <NotFoundPage /> },
];

// ###===> PROVIDER ROUTES
// ###===> PROVIDER ROUTES
// ###===> PROVIDER ROUTES
export const providerRoutes: IRoute[] = [
  { path: AllRoutes.providerDashboard, component: <DashboardPage /> },
  { path: AllRoutes.providerAccountSetup, component: <AccountSetupPage /> },

  { path: AllRoutes.providerShipment, component: <ShipmentPage /> },
  { path: AllRoutes.providerCreateSingleOrder(""), component: <CreateSingleOrderPage /> },
  { path: AllRoutes.providerCreateBulkOrder, component: <CreateBulkOrderPage /> },
  {
    path: AllRoutes.providerShipmentRoute(":shipmentId"),
    component: <ShipmentRoutePage role="provider" />,
  },

  { path: AllRoutes.providerCustomer, component: <CustomerPage /> },
  { path: AllRoutes.providerAddCustomer, component: <AddCustomerPage /> },

  { path: AllRoutes.providerCourier, component: <CourierPage /> },
  { path: AllRoutes.providerAddCourier, component: <AddCourierPage /> },
  { path: AllRoutes.providerViewCourier(":id"), component: <ViewCourierPage /> },

  { path: AllRoutes.providerPayroll, component: <PayrollPage /> },
  { path: AllRoutes.providerAddPayroll, component: <CreatePayrollPage /> },
  { path: AllRoutes.providerEditPayroll, component: <EditPayrollPage /> },

  { path: AllRoutes.providerTransactions, component: <TransactionsPage /> },

  { path: AllRoutes.providerSettings, component: <SettingsPage /> },
];

// ###===> CLIENT ROUTES
// ###===> CLIENT ROUTES
// ###===> CLIENT ROUTES
export const clientRoutes: IRoute[] = [
  {
    path: AllRoutes.clientEntryPoint,
    component: isRideHailingTenant() ? <ClientBookARidePage /> : <ClientDashboardPage />,
  },
  {
    path: AllRoutes.clientDashboard,
    component: isRideHailingTenant() ? <ClientBookARidePage /> : <ClientDashboardPage />,
  },
  { path: AllRoutes.clientCreateSingleOrder(""), component: <ClientCreateSingleOrderPage /> },

  { path: AllRoutes.clientProfile, component: <ClientProfilePage /> },

  { path: AllRoutes.clientRecentActivities, component: <ClientRecentActivitiesPage /> },

  { path: AllRoutes.clientOrderHistory, component: <ClientOrderHistoryPage /> },

  {
    path: AllRoutes.clientOrderRoute(":orderId"),
    component: <ShipmentRoutePage role="client" />,
  },

  { path: AllRoutes.clientEstimate, component: <ClientEstimatePage /> },
];

function RenderRoutes() {
  return (
    <Routes>
      {/* Not all require AuthLayout */}
      {routes.map(e => (
        <Route key={e.path} path={e.path} element={e.component} />
      ))}

      <Route element={<ProtectAuth role="provider" />}>
        {providerRoutes.map(e => (
          <Route key={e.path} path={e.path} element={e.component} />
        ))}
      </Route>

      <Route element={<ProtectAuth role="client" />}>
        {/* TODO: Move the DashboardLayout (& AuthLayout) to this file to avoid unnecessary re-rendering */}
        {clientRoutes.map(e => (
          <Route key={e.path} path={e.path} element={e.component} />
        ))}
      </Route>
    </Routes>
  );
}

export default RenderRoutes;
