import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import Fonts from "components/global/Fonts";
import ScrollToTop from "components/global/Layouts/ScrollToTop";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { theme } from "theme/color";
import { ToastContainer } from "react-toastify";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "redux/store";

import "mapbox-gl/dist/mapbox-gl.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <ChakraProvider theme={theme}>
          <Fonts />
          <App />
          <ToastContainer theme="colored" />
        </ChakraProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
);
